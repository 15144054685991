<template>
	<div class="page container">
		<v2-welcome />
		<div class="separator" />
		<div class="content">

			<div class="main courses">
				<p class="courses__title">
					My Learning
				</p>
				<p class="courses__subtitle">
					You have <span class="bold">{{ progressCourses.length }} courses in progress</span>
				</p>

				<div class="courses__list" v-if="progressCourses.length">
					<v2-course-card v-for="course in progressCourses" :key="course.id" :course="course" />
				</div>

				<div class="courses__list-empty border-box" v-else>
					<div class="courses__list-empty--text">
						Start to improve your game with <br /> Bridge128 courses
					</div>
					<router-link class="courses__list-empty--link button" to="/browse-courses">
						Discover FREE courses
					</router-link>
				</div>

				<p class="courses__subtitle">
					You have <span class="bold">completed {{ completedCourses.length }} course</span>
				</p>

				<template v-if="completedCourses.length">
					<div class="courses__list-small">
						<v2-course-card-small v-for="course in completedCourses" :key="course.id" :course="course" />
					</div>

					<div class="courses__more border-box">
						<div class="courses__more--text">
							Get better with more Bridge128 courses
						</div>
						<div class="courses__more--text mini">
							Get better with Bridge128 courses
						</div>
						<router-link class="courses__more--link button" to="/browse-courses">
							Discover FREE courses
						</router-link>
					</div>
				</template>

			</div>

			<div class="separator mini" />
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"showTopPicks": true
		}
	}
</route>

<script>
	import api      from '@/services/api';
	import V2Welcome  from '@/components/v2/myLearnings/V2Welcome.vue';
	import V2CourseCard  from '@/components/v2/courses/V2CourseCard.vue';
	import V2CourseCardSmall  from '@/components/v2/courses/V2CourseCardSmall.vue';

	export default {
		metaInfo: {
			title: 'My courses'
		},

		layout: 'v2default',

		components: {
			V2CourseCard,
			V2Welcome,
			V2CourseCardSmall
		},

		data: () => ({
			courses: [],
			pagination: {
				perPage: 21,
				currentPage: 1,
				totalPages: 0
			},
			totalItems:  0
		}),
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/my-courses',
						text: 'My courses'
					}
				].filter(Boolean);
			},

			progressCourses () {
				return this.courses.filter((item) => {
          if (item.live) {
            return item.liveCount !== item.endedCount;
          }
          return item.activityProgress !== 100;
				});
			},

			completedCourses () {
				return this.courses.filter((item) => {
          if (item.live) {
            return item.liveCount === item.endedCount;
          }
          return item.activityProgress === 100;
				});
			}
		},
		created () {
			this.setCourses();
		},
		methods: {
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setCourses();
			},

			async setCourses () {
				const response = await api.courses.getCurrentUsersCourses({
					include: 'metadata',
					searchString: '',
					page: -1,
					per: this.pagination.perPage
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}

				this.totalItems = response.meta.totalItems;
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.courses = response.results;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>

<style lang="scss" scoped>

.content {

  .courses {
    width: 100%;

    &__title {
      @include font(24px, $c-darkest-navy, 500, 31px);
      margin-bottom: 16px;
    }

    &__subtitle {
      @include font(18px, $c-darkest-navy, 400, 23px);
      margin-bottom: 16px;

      .bold {
        font-weight: 700;
      }
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 12px;
      margin-bottom: 36px;

      .course-card {
        width: 100%;
      }
    }

    &__list-small {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 12px;
    }

    .border-box {
      border-radius: 4px;
      border: 1px dashed $c-gray-d;
    }

    &__more {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 94px;
      padding: 20px;

      &--text {
        width: 180px;
        @include font(18px, $c-medium-navy, 400, 23px);

        &.mini {
          display: none
        }
      }

    }

    &__list-empty {
      display: flex;
      flex-direction: column;
      gap: 24px;
      height: 335px;
      max-width: 440px;
      width: 100%;
      padding: 12px;
      align-items: center;
      justify-content: center;
      margin-bottom: 36px;

      &--text {
        @include font(18px, $c-medium-navy, 400, 23px);
        text-align: center;
      }

    }
  }

  @media (max-width: 1279px) {
    flex-direction: column;

    .side {
      width: 100%;
    }

    .courses {
      width: 100%;

      &__title {
        font-size: 20px;
        line-height: 26px;
      }

      &__list {
        grid-template-columns: 1fr;
      }

      &__more {
        flex-direction: column;
        gap: 12px;
        height: 123px;
        padding: 26.5px 20px;

        &--text {
          display: none;

          &.mini {
            display: flex;
            font-size: 16px;
            line-height: 20px;
            width: 100%;
            justify-content: center;
          }
        }

        &--link {
          height: 37px;
          padding: 8px 20px;
          font-size: 16px;
          line-height: 20px;
        }

      }

      &__list-empty {
        height: 200px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    .courses {

      &__list {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

</style>
